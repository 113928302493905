import React, { Component } from 'react'
import withSizes from 'react-sizes'

import EngineersIcon from '../../assets/images/icons/engineers.png'
import ManagersIcon from '../../assets/images/icons/managers.png'
import LeadersIcon from '../../assets/images/icons/leaders.png'
import DistributedTeamsIcon from '../../assets/images/icons/distributed-teams.png'
import CoLocatedTeamsIcon from '../../assets/images/icons/co-located-teams.png'

class Adapts extends Component {
  componentDidMount() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { isMobile } = this.props

    if (!isMobile && !this.state) {
      console.log('Both isMobile and state are null')
      return <section className="adapt" />
    }

    if (!isMobile || !this.state) {
      return (
        <section className="adapt row">
          <div className="col-xs-12">
            <h2 className="adapt__heading heading heading--md">
              Ally adapts based on role and team
            </h2>
          </div>
          <div className="col-xs-6">
            <div className="adapt__feature row">
              <div className="feature__icon col-xs-3">
                <img src={EngineersIcon} alt="Engineers" />
              </div>
              <div className="feature__text col-xs-9">
                <p className="heading heading--sm">Engineers</p>
                <p className="small">
                  Discover when, where, and why your engineers struggle.
                </p>
                <a className="feature__link" href="/engineer">
                  Learn more
                  <span className="visually-hidden">about engineers</span>
                </a>
              </div>
            </div>
            <div className="adapt__feature row">
              <div className="feature__icon col-xs-3">
                <img src={ManagersIcon} alt="Managers" />
              </div>
              <div className="feature__text col-xs-9">
                <p className="heading heading--sm">Managers</p>
                <p className="small">
                  Understand your team, help others and identify opportunities
                  for professional growth.
                </p>
                <a className="feature__link" href="/manager">
                  Learn more
                  <span className="visually-hidden">about managers</span>
                </a>
              </div>
            </div>
            <div className="adapt__feature row">
              <div className="feature__icon col-xs-3">
                <img src={LeadersIcon} alt="Leaders" />
              </div>
              <div className="feature__text col-xs-9">
                <p className="heading heading--sm">Product Leaders</p>
                <p className="small">
                  Set and monitor team goals. Understand your team’s progress.
                </p>
                <a className="feature__link" href="/product-manager">
                  Learn more
                  <span className="visually-hidden">about product leaders</span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-xs-6">
            <div className="adapt__feature row">
              <div className="feature__icon col-xs-3">
                <img src={DistributedTeamsIcon} alt="Distributed teams" />
              </div>
              <div className="feature__text col-xs-9">
                <p className="heading heading--sm">Distributed teams</p>
                <p className="small">
                  Curious who needs help? Increase team visibility when you
                  don’t sit together.
                </p>
                <a className="feature__link" href="/distributed-teams">
                  Learn more
                  <span className="visually-hidden">
                    about distributed teams
                  </span>
                </a>
              </div>
            </div>
            <div className="adapt__feature row">
              <div className="feature__icon col-xs-3">
                <img src={CoLocatedTeamsIcon} alt="Co-located teams" />
              </div>
              <div className="feature__text col-xs-9">
                <p className="heading heading--sm">Co-located teams</p>
                <p className="small">
                  Digital team visibility platform helps you diagnose problems.
                </p>
                <a className="feature__link" href="/colocated-teams">
                  Learn more
                  <span className="visually-hidden">
                    about co-located teams
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="adapt adapt--mobile">
          <h2 className="adapt__heading heading heading--sm">
            Ally benefits every member of your team
          </h2>

          <div className="adapt__feature">
            <p className="heading heading--sm">Managers</p>
            <p className="small">
              Discover when, where, and why your engineers struggle.
            </p>
            <a href="/manager" className="btn">
              Learn benefits
            </a>
          </div>
          <div className="adapt__feature">
            <p className="heading heading--sm">Engineers</p>
            <p className="small">
              Understand your team, help others and identify opportunities for
              professional growth.
            </p>
            <a href="/engineer" className="btn">
              Learn benefits
            </a>
          </div>
          <div className="adapt__feature">
            <p className="heading heading--sm">Product Leaders</p>
            <p className="small">
              Set and monitor team goals. Understand your team’s progress.
            </p>
            <a href="/product-manager" className="btn">
              Learn benefits
            </a>
          </div>

          <h2 className="adapt__heading heading heading--sm">
            Ally benefits every team
          </h2>

          <div className="adapt__feature">
            <p className="heading heading--sm">Distributed teams</p>
            <p className="small">
              Curious who needs help? Increase team visibility when you don’t
              sit together.
            </p>
            <a href="/distributed-teams" className="btn">
              Learn benefits
            </a>
          </div>
          <div className="adapt__feature">
            <p className="heading heading--sm">Co-located</p>
            <p className="small">
              Digital team visibility platform helps you diagnose problems.
            </p>
            <a href="/colocated-teams" className="btn">
              Learn benefits
            </a>
          </div>
        </section>
      )
    }
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapSizesToProps)(Adapts)
