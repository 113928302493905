import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Hero from '../sections/Hero/Hero'
import Showcase from '../sections/Showcase/Showcase'
import Adapts from '../sections/Adapts/Adapts'
import Highlights from '../sections/Highlights/Highlights'
import Connect from '../sections/Connect/Connect'
import Try from '../sections/Try/Try'
import RequestDemo from '../sections/RequestDemo/RequestDemo'

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Seo />
      <Container>
        <Hero />

        <Showcase />

        <Highlights
          items={[
            {
              heading: 'Ally provides visibility from idea to production',
              subheading:
                'Fully-customizable dashboards enable you to diagnose and act on team issues.',
              imageName: 'home-highlight-1.png'
            }, {
              heading: 'Activities feed helps you increases transparency',
              subheading:
                'Activities feed brings together important actions related to your team.',
              imageName: 'home-highlight-2.png'
            }, {
              heading: 'Alerts help you stay on top of your day',
              subheading:
                'Receive alerts in Slack or Microsoft Teams when something has or has not happened.',
              imageName: 'home-highlight-3.png',
              imageMobileName: 'home-highlight-3.png'
            }, {
              heading: 'Combine survey results with data from your tools',
              subheading:
                'Run surveys with your team and combine results with events and data from connected tools like GitHub, Jira or Slack.',
              imageName: 'daily-team-survey.png',
              imageMobileName: 'daily-team-survey.png'
            },
          ]}
        />

        <RequestDemo />

        <Adapts />

        <Connect />

        <Try />
      </Container>
    </Layout>
  )
}

export default IndexPage
